// reset
// @import "_lib/reset/_normalize.scss";

// Bourbon
@import "_lib/jquery-ui.css.scss";
@import "_lib/bourbon/_bourbon.scss";

// Bitters
@import "_lib/base/_variables.scss";
@import "_lib/base/_typography.scss";
@import "_lib/base/_lists.scss";
@import "_lib/base/_buttons.scss";


// Ratchet
@import "_lib/ratchet/ratchet.scss";
// @import "_lib/ratchet/theme-ios.scss";

// components
// @import "components/_homescreen.scss";
@import "components/_navigation.scss";
@import "components/_layout.scss";
@import "components/_homescreen.scss";
@import "components/_checkin.scss";
@import "components/_feedback.scss";
@import "components/_project-card.scss";
@import "components/_no-invesments.scss";
@import "components/_cc-form.scss";
@import "components/_currency-input.scss";
@import "components/_transacton-new.scss";
@import "components/_employees.scss";
@import "components/_buttons.scss";

@import "components/_wip.scss";

// forms
@import "_lib/_formhack.scss";

// pages
@import "pages/_employee.scss";
@import "pages/_login.scss";
@import "pages/_home.scss";
@import "pages/_settings.scss";
@import "pages/_credit-redeem.scss";
@import "pages/_tip-view.scss";
@import "pages/_stripe-confirmation.scss";

@import "_lib/_stripe_connect.scss";
@import "pages/_signup.scss";
@import "pages/_start.scss";

//
// main styles
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  // color: #333;
  // background: #fafafa;

  background: white;
  color: black;
}

.quick-tip-form {
  padding-left: 1rem;
  padding-right: 1rem;
}

.quick-tip-results {
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  >* {
    margin-top: 2rem;
  }
}
