.login-block {
  & {
    position: absolute;
    overflow: hidden;
    z-index: 0;

    width: 100%;
    height: 100%;

    background: tint(black, 10) center no-repeat;
    background-image: url("/images/9.jpg") ;
    background-size: cover;


    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;

      background: rgba(black, 0.7);
    }
  }

  >.logo {
    display: block;
    position: relative;
    margin: 40px auto;
    @include size(80px);

    background: url("/svg/ikc-logo-w.svg");
    background-size: 80px 80px;
    opacity: 0.8;
  }
}

.form {
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  color: white;

  // font-size: em(14);

  padding: em(20);
  padding-top: em(180);

  .label {
    position: relative;
    color: tint(black, 80);
    text-align: center;
    font-size: em(12);
    margin-bottom: em(18);
  }

  input {
    font-size: em(14);
    height: auto;
    margin: 0 auto;
    padding: em(16) em(24);

    color: tint(black, 100);
    background: rgba(white, 0.2);
    border-radius: 0;

    @include placeholder {
      color: tint(black, 60);
    }

    &:not(:last-of-type) {
      // border-radius: 2px 2px 0 0 ;
      // border-top-width: 1px;
      border-bottom: 1px solid rgba(black, 0.55);
    }

  }

  .submit,
  [type=submit] {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    font-size: em(14);

    color: white;
    background: rgba($green, 0.9);
    border-radius: 3px;

    height: em(80);
    width: inherit;
    max-width: none;
    margin: 0;

    font-weight: bold;
    text-align: center;
    line-height: em(80);
    padding: 0;
  }

  >.actions {
    display: block;
    position: relative;

    // bottom: 0;
    margin-top: em(60);
    // width: 100%;
    color: white;

    font-size: em(12);
    // padding: em(50) 0;

    a {
      display: block;
      color: inherit;
      // text-decoration: underline;

      // width: 100%;
      text-align: center;
      line-height: em(60);
    }
  }
}
