.account-block {
  position: relative;;
  padding: 10px;
}

#profile-view {
  color: tint(black, 20);
  font-size: 16px;
  line-height: 150%;
  // position: relative;
}

.msg-banner {
  background-color: $green;
  height: 40px;
  text-align: center;
  padding: 10px;

  .msg {
    color: #fff;
    font-size: 16px;
  }
}

