#redeem-view {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.redeem-block {
  & {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    // background: shade($dark-green, 0);
    background: tint(black, 10);
    overflow: hidden;
  }

  >.redeem-form {
    & {
      display: flex;
      flex-flow: column;

      height: 100%;
    }

    >.projectselect {
      height: em(60);
      line-height: em(60);
      text-align: center;
      border-bottom: 1px solid rgba(white, 0.1);

      >select {
        background: none;
        color: white;
        text-align: center;
        // height: 100%;
        height: inherit;
        margin: 0 auto;
      }
    }
    >.chargeinput {
      flex: 1 1 100%;

      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      // background: tint($green, 0);
      // font-size: em(16 * 3.4px);
      // font-weight: bold;

      // height: $height;
      // line-height: $height;
      // text-align: center;
      // border-bottom: 1px solid rgba(white, 0.1);

      input {
        $height: 160px;

        display: inline-block;

        height: $height;
        line-height: $height;

        background: none;
        color: white;

        font-size: em(16 * 3.4px);
        text-align: center;
        // font-size: inherit;
        // font-weight: bold;

        margin: 0 ;
        padding: 0;

        @include placeholder {color: white;}
      }
    }
    .info {
      display: block;
      width: 90%;
      margin: 0 auto;
      text-align: center;
      font-size: em(11);
      font-weight: normal;
      color: tint(black, 60);
    }

    >.user-card {
      display: block;
      position: absolute;
      bottom: 0;
      // margin-bottom: em(60);
      // width: inherit;
      width: 100%;
      // height: 20vh;
      // background: rgba($green, 0.1);
      padding: em(20) em(10) em(80);

      font-size: em(16);

      .user-info {
        font-size: em(10);
      }

      // >.avatar {
      //   height: em(40);
      //   width: em(40);
      //   // margin: em(30) auto;
      //   margin: 0 auto;
      //
      //   border: 1px solid white;
      //   border-radius: 50%;
      //
      //   background: gray center no-repeat;
      //   background-image: url("/images/potus.png");
      //   background-size: cover;
      // }
      // >.name {
      //   // color: tint($dark-green, 10);
      //   font-size: em(12);
      //   width: 100%;
      //   text-align: center;
      //   font-weight: bold;
      //
      //   line-height: em(80);
      //   height: em(80);
      //   // margin: em(40) auto;
      // }
    }
    .submit,
    [type=submit] {
      display: block;
      position: absolute;
      z-index: 0;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;

      margin: 0;
      padding: 0;

      width: 100%;
      max-width: none;
      height: em(80);
      line-height: em(80);

      font-size: em(16);
      font-weight: bold;
      text-align: center;
      color: tint($dark-green, 90);
      background: shade($green, 10);

      border: none;
      border-radius: 0;
      border-top: 1px solid rgba(white, 0.1);
    }
  }
}
#pin-view {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
// .redeem-block {
//   & {
//     display: block;
//     position: absolute;
//     top: 0;
//     height: 100%;
//     width: 100%;
//
//     // background: shade($dark-green, 0);
//     background: tint(black, 10);
//     overflow: hidden;
//   }
.pin-block {
  & {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    background: tint(black, 90);
    color: tint(black, 20);
    overflow: hidden;
  }

  >.pin-form {
    display: flex;
    flex-flow: column;
    height: 100%;

    >.projectselect {
      height: em(60);
      line-height: em(60);
      text-align: center;
      border-bottom: 1px solid rgba(black, 0.1);

      >select {
        background: none;
        color: inherit;
        text-align: center;
        // height: 100%;
        height: inherit;
        margin: 0 auto;
      }
    }
    >.pininput {
      >input {
        height: auto;
        background: none;
        color: inherit;
        text-align: center;
        font-size: em(16*4);
        padding: 10% 0;
        margin: 0 auto;

        // input[type=number] {
          -webkit-text-security: disc;
        // }
        @include placeholder {
          // color: inherit;
          -webkit-text-security: disc;
        }
      }
    }
    >.info {
      display: block;
      width: 90%;
      margin: 0 auto;
      text-align: center;
      font-size: em(12);
      font-weight: normal;
      // color: tint(black, 20);
    }
    .submit,
    [type=submit] {
      display: block;
      position: absolute;
      z-index: 0;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;

      margin: 0;
      padding: 0;

      width: 100%;
      max-width: none;
      height: em(80);
      line-height: em(80);

      font-size: em(16);
      font-weight: bold;
      text-align: center;
      color: tint($dark-green, 100);
      background: tint($dark-green, 20);

      border: none;
      border-radius: 0;
      border-top: 1px solid rgba(white, 0.1);

      &.-is-disabled {
        pointer-events: none;
        background: tint(black, 50);
        color: tint(black, 75);
        // cursor: not-allowed;
      }

    }
  }
}

.creditbalance {
  & {
    position: relative;
    color: tint(black, 20);
    background: tint(black, 92);
    text-align: center;

    padding: em(16);

    select { margin: 0 auto; }
  }

  >.balance {
    height: auto;
    background: none;
    color: inherit;
    text-align: center;
    font-size: em(16*4);
    padding: 10% 0;
    margin: 0;
  }
}

%redeembutton {
  display: block;

  position: relative;
  // position: absolute;
  // top: auto;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // z-index: 0;

  margin: 0;
  padding: 0;

  width: 100%;
  max-width: none;

  height: 100px;
  // max-height: 100%;
  line-height: 80px;

  // font-size: em(16);
  font-weight: bold;
  text-align: center;
  color: tint($dark-green, 90);
  background: shade($green, 10);

  border: none;
  border-radius: 0;
  border-top: 1px solid rgba(white, 0.1);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &:active, &:focus, &:hover {
    color: inherit;
    background: shade($green, 30);
    @include transition(background 500ms ease);
  }
}

.redeembutton {
  @extend %redeembutton;
}

%view {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

#credit-account-view {
  // & {
    // @extend %view;
    // background: tint($green, 5);
    //
    // position: relative;
    // overflow: hidden;
    // overflow: hidden;
    // top: 0;
    // background: tint($dark-green, 90);
  // }

  .accounttransactions {
    padding: (16px) (8px);

    .transaction-list {
      background: tint(black, 100);
      color: tint(black, 20);

      // text-align: center;

      >.transaction-item {
        & {
          display: flex;
          // flex-direction: row;
          // flex-wrap: wrap;
          flex-flow: row wrap;

          line-height: em(40);
        }
        &:not(:last-of-type) {
          border-bottom: 1px solid tint(black, 70);
        }

        >.datetime {
          // flex-grow: 1;
          flex: 1 0 auto;

          font-size: em(12);
          padding: em(0) em(16);
        }
        >.ammount {
          flex: 0 1 auto;

          // font-size: em(16);
          // font-weight: bold;
          padding: em(0) em(16);

          >.status {
            font-size: 8px;
            font-weight: normal;
            text-align: center;
            text-transform: uppercase;

            color: tint(black, 40);
            border: 1px solid tint(black, 80);

            margin-right: 8px;
            padding: em(6) em(12);
          }
          >.price {
            display: inline-block;
            font-weight: bold;
            font-size: 18px;
          }
        }

        >.notes {
          font-size: em(12);
          display: block;
          width: 100%;
          padding: em(0) em(16);

          text-align: left;
          color: tint(black, 40);
          background: tint(black, 98);
          border-top: 1px solid tint(black, 94);
        }

      }

    }
  }

  // .projectinfo {
  //   background: tint(black, 96);
  //   color: tint(black, 20);
  //   position: relative;
  //   // text-align: center;
  //   z-index: 0;
  //   // margin: em(16) auto;
  //   margin: em(16) em(8);
  //
  //   border-radius: 4px;
  //   overflow: hidden;
  //
  //   >.body {
  //     padding: em(16);
  //
  //   }
  //   >.head {
  //     background: tint(black, 90);
  //     padding: 0 em(16);
  //     border-bottom: 1px solid tint(black, 100);
  //   }
  //
  //   .name {
  //     position: relative;
  //     z-index: 1;
  //
  //     font-size: em(22);
  //
  //     height: em(40);
  //     line-height: em(50);
  //   }
  //   .address {
  //     position: relative;
  //     z-index: 1;
  //     font-size: em(12);
  //
  //     height: em(60);
  //     line-height: em(60);
  //
  //     a {
  //       color: inherit;
  //       text-decoration: underline;
  //     }
  //   }
  //   .hours {
  //     ul {
  //       font-size: em(14);
  //       text-align: left;
  //       margin: 0;
  //       padding: 0;
  //       list-style: none;
  //     }
  //   }
  //   .figure {
  //     background: tint(black, 80) center no-repeat;
  //     background-size: cover;
  //     height: 0;
  //     padding-top: 50%;
  //     position: relative;
  //     top: 0;
  //     width: 100%;
  //     z-index: 0;
  //     //
  //     // &:after {
  //     //   content: '';
  //     //   display: block;
  //     //   position: absolute;
  //     //   top: 0;
  //     //   left: 0;
  //     //   width: 100%;
  //     //   height: 100%;
  //     //   background: rgba(black, 0.5);
  //     // }
  //   }
  // }
}


.user-navigation {
  // & {
    // background: tint($green, 10);
    // background: $dark-green;
    // border-bottom: 1px solid rgba(white, 0.1);
    // height: em(60);
    // line-height: em(60);
    // overflow: hidden;
    // position: relative;
  // }

  .name {
    font-size: em(14);
    // text-align: center;
    // height: em(60);
    // line-height: em(60);
    padding: 0 em(16*2);
    color: white;
  }

  .avatar {
    background: tint(black, 80) center no-repeat;
    background-size: cover;
    border: 1px solid rgba(white, .9);
    border-radius: 50%;
    height: em(24);
    width: em(24);
    margin: auto;
    margin-right: em(16);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

@import "_thanks.scss";
