%-view {
  & {
    position: absolute;
    top: 0;
    z-index: 9999;

    width: 100vw;
    height: 100vh;

    // color: tint(black, 20);
    // background: rgba(white, 1.0);
    background: tint(black, 90);

    display: flex;
    flex-flow: column;
  }
  >.head {
    flex: auto;

    display: flex;
    flex-flow: row;
    align-items: center;
    // align-content: flex-end;
    // justify-content: center;

    position: relative;
    overflow: hidden;

    font-size: em(14);
    font-weight: bold;
    text-align: center;

    white-space: nowrap;

    height: (60px);
    line-height: (60px);

    border-bottom: 1px solid tint(black, 90);

    background: white;
    color: tint(black, 20);

    >.title {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // right: 0;

      display: flex;
      flex-flow: row;
      align-items: center;
      // align-content: flex-end;
      justify-content: center;
    }

    >button.close {
      & {
        display: block;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }

      & {
        position: relative;
        margin-left: auto;
        margin-right: em(8);

        display: block;
        @include size(32px);
        background: center no-repeat url("/svg/x-icon-01.svg");
        background-size: 14px;
        opacity: 0.25;
      }
    }
  }
  >.body {
    & {
      flex: 100%;
      // overflow: hidden;
      @extend %-is-scrollable;

      padding-bottom: (60px);
    }
  }
}

.add-cc-block {
  & {
    position: absolute;
    top: 0;
    z-index: 9999;

    width: 100vw;
    height: 100vh;

    // color: tint(black, 20);
    // background: rgba(white, 1.0);
    background: tint(black, 90);

    display: flex;
    flex-flow: column;
  }
  >.head {
    flex: auto;

    display: flex;
    flex-flow: row;
    align-items: center;
    // align-content: flex-end;
    // justify-content: center;

    position: relative;
    overflow: hidden;

    font-size: em(14);
    font-weight: bold;
    text-align: center;

    white-space: nowrap;

    height: (60px);
    line-height: (60px);

    border-bottom: 1px solid tint(black, 90);

    background: white;
    color: tint(black, 20);

    >.title {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // right: 0;

      display: flex;
      flex-flow: row;
      align-items: center;
      // align-content: flex-end;
      justify-content: center;
    }

    >button.close {
      & {
        display: block;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }

      & {
        position: relative;
        margin-left: auto;
        margin-right: em(8);

        display: block;
        @include size(32px);
        background: center no-repeat url("/svg/x-icon-01.svg");
        background-size: 14px;
        opacity: 0.25;
      }
    }
  }
  >.body {
    & {
      flex: 100%;
      // overflow: hidden;
      @extend %-is-scrollable;

      padding-bottom: (60px);
    }
  }

}

.cc-form {

  // input {
  //   border: 2px solid tint(black, 80);
  // }
  //
  // .field-row {
  //   display: flex;
  // }
  // .cell {
  //   flex: 1;
  //
  //   &:not(:last-of-type) {
  //     margin-right: 8px;
  //   }
  // }

  & {
    font-size: 16px;
  }

  .form-group {
    input {
      font-size: 20px;
      line-height: 150%;

      height: auto;
      padding: 12px 16px;

      border: 2px solid gray;
      // border-color: inherit;;
    }
    input:hover,
    input:active {
      background: tint($blue, 90);
      border-color: $blue;
    }
  }
  .form-group.has-error {
    label {
      color: red;
    }
    input {
      // border: 2px solid red;
      border-color: red;
    }
  }
}

.payment-block {
  color: tint(black, 25);

  // & {
  //   @extend %-view;
  // }
}
