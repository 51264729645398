#settings-view {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.settings-block {
  display: block;
  // position: absolute;
  // top: 0;
  // height: 100%;
  // width: 100%;
  // overflow: hidden;

  color: tint(black, 20);
  // background: tint(black, 90);

}

.user-info {
  & {
    margin: 0 auto;
    padding: em(32) 0;
  }
  >.name {
    $height: em(60);
    font-size: em(14);
    text-align: center;
    height: $height;
    line-height: $height;
    padding: 0 em(16*2);
  }
  >.avatar {
    $size: em(80);
    background: tint(black, 80) center no-repeat;
    background-size: cover;
    border: 1px solid rgba(white, .9);
    border-radius: 50%;
    height: $size;
    width: $size;
    margin: auto;
  }
}

.negative-color {
  color: $negative-color;
}

.primary-color {
  color: $primary-color;
}
