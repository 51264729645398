// helpers
@mixin test-overflow {
  .-testoverflow {
    display: block;
    width: 100%;
    height: 200vh;
    background: repeating-linear-gradient(
      -55deg,
      #222,
      #222 10px,
      #333 10px,
      #333 20px
    );
  }
}

.feedback-bttn {
  background: $green;
  color: white;

  display: block;

  width: calc(100% - (18*2px));
  // width: auto;
  max-width: none;

  font-size: em(12);

  height: (40px);
  line-height: (40px);

  border-radius: 20px;

  margin: em(16) auto;
  padding: 0;
}
// main stuff
.feedback-block {
  & {
    position: absolute;
    top: 0;
    z-index: 9999;

    width: 100vw;
    height: 100vh;

    color: tint(black, 20);
    background: rgba(white, 1.0);

    display: flex;
    flex-flow: column;
  }

  @include test-overflow;

  >.head {
    flex: auto;

    display: flex;
    flex-flow: row;
    align-items: center;
    // align-content: flex-end;
    // justify-content: center;

    position: relative;
    overflow: hidden;

    font-size: em(14);
    font-weight: bold;
    text-align: center;
    white-space: nowrap;

    height: (60px);
    line-height: (60px);

    border-bottom: 1px solid tint(black, 90);

    >.title {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // right: 0;

      display: flex;
      flex-flow: row;
      align-items: center;
      // align-content: flex-end;
      justify-content: center;
    }

    >button.close {
      & {
        display: block;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }

      & {
        position: relative;
        margin-left: auto;
        margin-right: em(8);

        display: block;
        @include size(32px);
        background: center no-repeat url("/svg/x-icon-01.svg");
        background-size: 14px;
        opacity: 0.25;
      }
    }
  }
  >.body {
    & {
      flex: 100%;
      // overflow: hidden;
      @extend %-is-scrollable;

      padding-bottom: (60px);
    }

    form {
      margin: 0 auto;
      padding: em(16);
      width: 100%;
      max-width: 320px;
    }
    fieldset {
      padding: 0;
    }
    label {
      text-align: center;
      font-size: em(14);
    }
    textarea {
      color: #000;
      background: tint(black, 90);
    }

    >.info {
      font-size: em(12);
      font-weight: bold;
      text-align: center;
      padding: em(16);
      // background: darken(#F6D156, 0);
      background: tint(#F6D156, 25);
    }

  }
  >.footer {
    & {
      // display: block;
      display: flex;
      // flex-flow: column;

      position: absolute;
      bottom: 0;
      width: 100%;
    }

    button {
      width: 100%;
      max-width: none;

      height: auto;
      line-height: em(60);

      color: white;
      background: rgba($green, 0.96);
      // border-radius: 0;

      padding: 0;
      margin: em(12);
    }
  }
}
// .layout {
//   filter: blur(5px);
// }

.star-rating {
  padding: 0;
  margin: 0 auto;

  font-size: 0;
  white-space: nowrap;
  display: block;
  width: 250px;
  height: 50px;
  overflow: hidden;
  position: relative;
  background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjREREREREIiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
  background-size: contain;
}
.star-rating i {
  opacity: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  height: 100%;
  width: 20%;
  z-index: 1;
  background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkZERjg4IiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
  background-size: contain;
}
.star-rating input {
  -moz-appearance: none;
  -webkit-appearance: none;
  opacity: 0;
  display: inline-block;
  width: 20%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 2;
  position: relative;
}
.star-rating input:hover + i,
.star-rating input:checked + i {
  opacity: 1;
}
.star-rating i ~ i {
  width: 40%;
}
.star-rating i ~ i ~ i {
  width: 60%;
}
.star-rating i ~ i ~ i ~ i {
  width: 80%;
}
.star-rating i ~ i ~ i ~ i ~ i {
  width: 100%;
}

.star-rating + .choice {
  font-size: em(12);
  font-weight: bold;
  text-align: center;

  height: em(60);
  line-height: em(60);
}


// FIXME:
.project-block {
  & {
    background: tint(black, 90);
    position: relative;
    z-index: 0;

    height: 100px;

    display: flex;
    flex-flow: column;

    align-items: center;
    // align-content: flex-end;
    justify-content: flex-end;

    padding: em(16);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -8;

    // border-top: 1px solid tint(black, 50);
    // background-color: rgba(tint(black, 20), 0.8);
    background: linear-gradient(to top, rgba(black, 0.8) 20%, rgba(black, 0.4));
  }

  .name,
  .address {
    position: relative;
    flex: 0 auto;
    text-align: center;
    overflow: hidden;

    color: white;
    font-size: em(12);
    font-weight: bold;
  }

  >.address a {
    font-weight: normal;
    color: inherit;
    // text-decoration: underline;
  }

  >.figure {
    background: tint(black, 90) center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
  }

}
