#credit-account-view {
  position: relative;
  // width: 100%;
  // height: 100%;
  // max-height: 100%;

  overflow: hidden;
  // overflow-y: scroll;
  //
  // -webkit-overflow-scrolling: touch;
  //
  padding-bottom: 80px;
  // padding-top: 60px;
  //
  // &::-webkit-scrollbar {
  //   -webkit-appearance: none;
  //   display: none;
  // }
}

.user-navigation {
  & {
    height: em(60);
    line-height: em(60);
    overflow: hidden;
    position: relative;
    background: rgba($dark-green, 0.96);
    border-bottom: 1px solid $dark-green;

    // position: absolute;
    // top: 0;
    // width: 100%;
    // z-index: 9999;
  }

  .name {
    font-size: em(14);
    // text-align: center;
    // height: em(60);
    // line-height: em(60);
    padding: 0 em(16*2);
    color: white;
  }

  .avatar {
    background: tint(black, 80) center no-repeat;
    background-size: cover;
    border: 1px solid rgba(white, .9);
    border-radius: 50%;
    height: em(24);
    width: em(24);
    margin: auto;
    margin-right: em(16);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.back-nav {
  background: $dark-green;
  border-bottom: 1px solid rgba(white, 0.1);
  height: em(60);
  line-height: em(60);
  overflow: hidden;
  position: relative;

  // font-size: em(14);
  color: white;
  padding: 0 em(16*2);

  a {
    position: relative;
    color: inherit;
  }
  a::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8*-2px;
    border: solid white;
    border-width: 0 0 2px 2px;
    width: 8px;
    height: 8px;
    margin: auto;

    @include transform(rotate(45deg));
  }
}

.loading-gif {
  margin: 0 auto;
  display: block;
  text-align: center;

  img {
    display: block;
    margin: 0 auto;
  }
}

.loading-svg {
  display: flex;
  align-items: center;
  justify-content: center;

  @include size(100%);

  position: fixed;
  top: 0;
  z-index: 999999;
  // background: rgba(black, 0.5);
}

.headroom {
    transition: transform 200ms linear;
}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--unpinned {
    transform: translateY(-100%);
}
