.no-investments {
  & {
    display: flex;
    flex-flow: column;
    //
    align-items: center;
    // align-content: center;
    justify-content: center;

    height: 75vh;

    color: tint(black, 50);
  }
  
  a {
    color: lighten($green, 5);
  }

  .-button {
    @extend %card-style;
    @extend %no-select;

    // display: block;
    appearance: button;
    text-decoration: none;

    padding: 0 10%;
    // font-size: em(14);
    font-weight: bold;
    height: em(40);
    line-height: em(40);
    color: lighten($green, 10);
  }
}
