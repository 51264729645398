%confirmation-block {
  & {
    display: flex;
    flex-flow: column;

    // align-items: center;
    // align-content: center;
    // justify-content: center;

    padding: 1em;
    height: 100%;

    background: tint(black, 95);
    color: tint(black, 25);
    text-align: center;

  }

  >.body {
    flex: 1 0 auto;

    display: flex;
    flex-flow: column;

    // align-items: center;
    // align-content: center;
    justify-content: center;
  }

  p {
    color: inherit;
  }
}


#stripe-confirmation {
  @extend %confirmation-block;

  position: absolute;
  height: 100%;
  width: 100%;

  padding-bottom: 10vh;
}
