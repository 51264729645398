%-noselect {
  user-select: none;
  // -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.bttn-next {
  // flex: 0 0 auto;
  //
  font-weight: bold;
  color: white;
  background: $blue;

  height: 80px;

  border: none;
  margin: 0;
  padding: 0;

  // @extend %invest-button;

  @extend %-noselect;

  @include transition(
    background-color 400ms ease
  );

  &:hover {
    background-color: lighten($blue, 20);
    // box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
  }

  &:disabled {
    background-color: lighten($blue, 10);
    // box-shadow: none;
  }

  &.-loading {
    background: lighten($blue, 10) center no-repeat;
    background-image: url('/svg/oval.svg');
    background-size: auto 26px;

    // @include hide-text();
    font-size: 0;
    color: rgba(white, 0.0);
  }
}


// ratchet buttons loading state
.btn-loading {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/svg/oval.svg');
  background-size: auto 50%;

  // @include hide-text();
  // font-size: 0;
  color: rgba(white, 0.0);
}

%redeembutton {
  display: block;
  position: relative;

  margin: 0;
  padding: 0;

  width: 100%;
  max-width: none;

  line-height: 80px;
  height: 80px;

  font-size: 16px;
  font-weight: 600;
  text-align: center;

  // color: tint($dark-green, 90);
  // background: shade($green, 10);
  color: white;
  background: lighten($green, 5) center no-repeat;
  // background-repeat: no-repeat;
  // background-position: center;
  background-size: 28px;

  border: none;
  border-radius: 0;
  border-top: 1px solid rgba(white, 0.1);

  box-shadow: 0px 1px 8px 2px rgba(0,0,0,0.2);

  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -webkit-tap-highlight-color: rgba(0,0,0,0);
  @extend %no-select;
  @include transition(background 500ms ease);

  &:active,
  &:focus,
  &:hover {
    color: white;
    background: shade($green, 20) center no-repeat;
    background-size: 28px;
  }

  &.-is-loading {
    // background: shade($green, 5) center no-repeat;
    background: lighten($green, 5) center no-repeat;
    background-size: 28px;
    background-image: url('/svg/oval.svg');

    color: rgba(white, 0.0);
  }

}

.redeembutton {
  @extend %redeembutton;
}

%rounded-button {
  & {
    display: block;

    margin: 0;
    padding: 0;
    width: 100%;
    line-height: (60px);

    color: white;
    font-weight: bold;
    text-align: center;



    overflow: hidden;
    border-radius: (60px);

    // background: lighten($green, 10);
    background: lighten($green, 10) center no-repeat;
    border: none;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.0);

    // background: lighten($green, 10) center no-repeat;
    // background-image: url('/images/svg/oval.svg');
    // background-size: auto 50%;
  }

  &.-loading {
    background: lighten($green, 10) center no-repeat;
    background-image: url('/images/svg/oval.svg');
    background-size: auto 50%;

    // @include hide-text();
    font-size: 0;
    color: rgba(white, 0.0);
  }

  @extend %-noselect;

  @include transition(
    background-color 400ms ease,
    box-shadow 600ms ease
  );

  &:hover {
    background-color: lighten($green, 20);
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
  }

  &:disabled {
    background: lighten($green, 10);
    box-shadow: none;
  }

}
