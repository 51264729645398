// FIXME:
%card-style {
  overflow: hidden;
  position: relative;

  margin: em(16) em(8);

  background: white;
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2);
  border-radius: 2px;

  // color: inherit;
}

//
.project-credit {
  @extend %card-style;

  & {
    color: tint(black, 100);
    // background: tint($dark-green, 10);
    background: lighten($dark-green, 10);
  }

  >.balance {
    $height: 180px;

    & {
      font-size: em(16 * 3.4px);
      font-weight: lighter;
      text-align: center;
      height: $height;
      line-height: $height;
      // background: tint($green, 0);
      // border-bottom: 1px solid rgba(white, 0.1);
    }

    span {
      position: relative;
      display: inline-block;
      min-width: 100px;
      padding-top: 10px;
    }

    span:before {
      content: '$ ';
      font-size: 90%;
      position: absolute;
      right: 110%;
    }

    span:after {
      content: 'Credit Balance';
      font-size: 12px;
      font-weight: normal;
      opacity: 0.5;
      // width: 200px;
      height: 30px;
      line-height: 30px;
      position: absolute;
      // top: 110%;
      bottom: 75%;
      left: 0;
      right: 0;
      margin: auto;
      // right: 110%;
      overflow: visible;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  >.projectselect {
    & {
      // background: tint(black, 96);
      // background: shade($dark-green, 10);
      border-top: 1px solid rgba(white, 0.2);
      // height: em(80);
      // width: 100%;
      // padding: 0 12px;
    }
    form {
      // height: inherit;
      // width: inherit;

      padding: 1em;
    }
    select {
      // background: none;
      // color: white;
      // color: black;
      font-size: em(18);
      // height: inherit;
      margin: 0 auto;
      // margin: 16px;
      // width: 90%;
      // line-height: 90px;

      border: 1px solid tint($green, 0);
    }
  }
}


.global-modal {
  & {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;

    height: 100vh;
    width: 100vw;

    overflow: hidden;

    background: rgba( tint(black, 90), 0.9);
    color: tint(black, 20);

    display: flex;
    flex-flow: column;

    // align-items: center;
    align-content: center;
    justify-content: center;

    text-align: center;
  }

  >.closebttn {
    & {
      display: block;
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      border-radius: 0;
    }

    & {
      position: absolute;
      top: 0;
      right: 0;
      // position: relative;
      // margin-left: auto;
      // margin-right: em(0);

      // display: block;
      @include size(40px);
      background: center no-repeat url("/svg/x-icon-01.svg");
      background-size: 14px;
      opacity: 0.5;
    }
  }

  >.info {
    margin: 0 auto;
    max-width: 60vw;
    font-size: em(12);
  }
}

.maintenance-block {
  padding: 1em;
}

