#start-add {
  h3 {
    padding: 10px;
  }

  button[type=submit] {
    @extend %rounded-button;
    margin: 2rem auto;
  }

  .start-list {
    margin-left: 20px;
    list-style-type: circle;
  }

  input, textarea, select {
    border: 1px solid #333;
    margin-bottom: 20px;
  }
}

#start-show .content {
  padding-left: 20px;
  padding-right: 20px;

  h3 {
    padding-top: 15px;
  }

  .button {
    margin: 0 auto;
    height: 45px;
    text-align: center;
  }

  li {
    margin-bottom: 2rem;
  }

  img {
    max-width: 800px;
    margin: 0 auto 40px;
    display: block;
  }
}
