// .checkin-block {
//   position: relative;
//   margin: em(16) em(8);
//
//
//   >form >button {
//     width: 100%;
//     height: auto;
//     max-width: none;
//     background: tint(black, 20);
//     color: tint(black, 100);
//
//     line-height: em(52);
//     padding: 0;
//
//     @include transition(background-color 500ms ease);
//
//     &:hover {
//       background: tint(black, 0);
//     }
//   }
// }

.checkin-leaderboard {
  display: flex;
  flex-flow: column;

  position: relative;
  overflow: hidden;
  margin: em(16) em(8);
  background: white;
  border-radius: 2px;

  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2);

  >.head {
    background: tint($dark-green, 10);
    text-align: center;
    line-height: (40px);
    font-size: em(10);
    font-weight: bold;
  }

  >.body {
    display: flex;
    flex-flow: column;
  }
  >.foot {
    border-top: 1px solid tint(black, 90);

    // color: tint(black, 50);
    // text-align: center;

    button {
      margin: 1em auto;
      padding: 0;
      display: block;
      max-width: none;

      font-size: em(12);

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      height: auto;
      line-height: 40px;
      border-radius: 20px;
      color: white;
      background: lighten($dark-green, 10);

      width: calc(100% - 2em);

      @include transition(background-color 300ms ease);

      // &.-is-loading {
      //   background: tint(black, 50);
      //   // width: 40px;
      //   // overflow: hidden;
      // }

      &:hover {
        background: lighten($dark-green, 20);
      }
    }
  }

  >.body >.list {
    $item-height: 60px;

    overflow: hidden;

    max-height: ($item-height * 3);

    // & {
    //   position: relative;
    //   z-index: 1;
    //
    //   background: #FFF no-repeat;
    //   background-image:
    //      radial-gradient(farthest-side at 50% 0, rgba(0,0,0,0.2), rgba(0,0,0,0));
    //     //  radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,0.2), rgba(0,0,0,0));
    //   background-position: 0 0;
    //   background-size: 100% 14px;
    // }

    // &:before
    // // &:after
    // {
    //   content: "";
    //   position: relative;
    //   z-index: -1;
    //   display: block;
    //   height: 30px;
    //   margin: 0 0 -30px;
    //   background:   linear-gradient(to bottom,#FFF,#FFF 30%,rgba(255,255,255,0));
    // }

    // &:after {
    //   margin: -30px 0 0;
    //   background:   linear-gradient(to bottom,rgba(255,255,255,0),#FFF 70%,#FFF);
    // }

    display: flex;
    flex-flow: column;

    >.item {
      flex: auto;

      min-height: ($item-height);
      line-height: ($item-height);

      padding: 0 em(16);
      font-size: em(14);
      color: tint(black, 20);
      // background: white;

      // &:not(:last-of-type) {
        border-bottom: 1px solid tint(black, 90);
      // }

      &.-is-user {
        // order: -1;
        color: tint($green, 0);
        font-weight: bold;
        background: tint($green, 92);
      }

      display: flex;
      flex-flow: row;
      align-items: center ;

      >.avatar {
        flex: 0 0 auto;
        display: inline-block;
        @include size(40px);
        background: 100% tint(black, 80);
        background-size: cover;
        border-radius: 50%;
        border: 1px solid white;
      }
      >.user {
        flex: 1 1 auto;
        // padding: 0 16px;
        margin: 0 16px;

        line-height: 16px;
      }
      >.user >.checkincount {
        font-size: 10px;
        font-weight: bold;
        color: tint(black, 50);
      }
      >.place {
        flex: 0 1 auto;
        font-size: em(18);
        font-weight: bold;
        // @include size(40px);
      }
    }
  }
}

.checkin-card {
  @extend %card-style;

  margin: 0;
  padding: 0;
  font-size: em(14);

  >.figure {
    background: gray center no-repeat;
    background-size: cover;
    height: em(100);
  }

  >.body {
    padding: em(16);
  }
  >.footer {
    display: flex;
    flex-flow: column;
  }
  >.footer button {
    margin: 1em auto;
    padding: 0;

    display: block;
    max-width: none;

    font-size: em(12);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    height: auto;
    line-height: 40px;
    border-radius: 20px;

    color: white;
    background: lighten($dark-green, 10);

    width: calc(100% - 2em);

    @include transition(background-color 300ms ease);

    &:hover {
      background: lighten($dark-green, 20);
    }
  }
}
