#thanks-view {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.finish-button {
  display: block;
  width: 100%;

  max-width: none;
  margin: 0;
  padding: 0;

  height: em(60);
  line-height: em(60);

  background: $green;
  color: white;
  text-align: center;

  bottom: 0;
  position: absolute;

  border-radius: 0;

  &:active,
  &:hover {
    color: white;
    background: shade($green, 10);
  }
}

.thanks-block {
  padding-bottom: em(80);
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  color: tint(black, 20);
  background: tint(black, 90);

  // padding: em(32);
  text-align: center;

  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  >.row {
    & {
      text-align: left;
      background: tint(black, 100);
      border-bottom: 1px solid tint(black, 92);
    }
    // &:not(:last-of-type) {
    // }
  }
  >.row >.label{
    font-size: em(10);
    padding: (0) (16px);

    color: tint(black, 50);
    background: tint(black, 98);
    border-bottom: 1px solid tint(black, 92);

    line-height: (30px);
    font-weight: bold;
  }
  >.row >.value{
    font-size: em(16 * 1.4);
    padding: (0) (16px);

    line-height: (60px);
  }

  .info {
    background: tint(yellow, 80);
    border-top: 1px solid shade(tint(yellow, 80), 10);
    margin-top: em(16);

    display: block;
    margin: 0 auto;
    padding: em(24);

    text-align: center;
    font-weight: bold;
    font-size: em(11);
    color: tint(black, 30);

  }

  .gratuity-block {
    text-align: center;
    margin: em(16) auto;
  }

  // .flex-block {
  //   & {
  //     display: flex;
  //     flex-wrap: wrap;
  //
  //     margin: (16px) auto;
  //   }
  //
  //   label {
  //     flex: 1 1 auto;
  //
  //     background: #eee;
  //     border: 1px solid #ddd;
  //
  //     cursor: pointer;
  //     z-index: 1;
  //
  //     font-size: em(12);
  //     text-align: center;
  //
  //     margin: 0;
  //
  //     &:not(:last-of-type) {
  //       border-right: 0;
  //     }
  //   }
  //   // label:first-of-type {
  //   //   margin-left: 0;
  //   // }
  //   div {
  //     order: 1;
  //     width: 100%;
  //
  //     margin: (8px) auto 0 ;
  //     border: 1px solid #ddd;
  //
  //     font-size: em(20);
  //     font-weight: bold;
  //     text-align: center;
  //
  //   }
  //   input[type=radio], div {
  //     display: none;
  //   }
  //   input[type=radio]:checked + label {
  //     background: #fff;
  //   }
  //   input[type=radio]:checked + label + div {
  //     display: block;
  //   }
  // }
}
