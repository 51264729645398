/* FormHack v1.1.0 (formhack.io) */

// Config -----------------------------

// Font
// $fh-font-family: 'Raleway', sans-serif !default;
$fh-font-family: $base-font-family;
$fh-font-size: 16px !default;
$fh-font-color: rgb(40, 40, 40) !default;
$fh-font-color: tint(black, 20);

// Borders
// $fh-border-radius: 5px !default;
$fh-border-radius: 2px !default;
$fh-border-width: 0 !default;
$fh-border-style: solid !default;
// $fh-border-color: rgb(200, 200, 200) !default;
$fh-border-color: tint(black, 90) !default;

// Inputs, Textareas, Select, Option
// $fh-input-height: 40px !default;
$fh-input-height: 48px !default;
$fh-input-width: 100% !default;
$fh-input-max-width: 400px !default;
$fh-input-bg-color: #fff !default;
// $fh-focus-bg-color: rgb(220, 220, 220) !default;
$fh-focus-bg-color: white !default;
$fh-focus-border-color: $fh-border-color !default;
$fh-focus-font-color: $fh-font-color !default;

// Select Vendor Styling
$fh-allow-vendor-styling: true !default;
$fh-allow-vendor-styling: false;


// Fieldset & Legend Styling
$fh-fieldset-bare: false !default;

// Buttons & Input Submits
$fh-button-height: 40px !default;
$fh-button-width: 100% !default;
$fh-button-max-width: 200px !default;
$fh-button-font-color: $fh-font-color !default;
$fh-button-bg-color: $fh-focus-bg-color !default;
$fh-button-hover-bg-color: $fh-border-color !default;
$fh-button-hover-font-color: $fh-font-color !default;

// Layout
$fh-centered: false !default;
// $fh-centered: true;
$fh-display: block !default;


// Center/Left-Aligned Layout
@mixin center-layout {
	@if $fh-centered == true {
		margin: 10px auto;
		text-align: center;
	}
	@else {
		margin: 10px 0;
	}
}


/* Global Reset Styles ------------------ */

input,
textarea,
select,
option,
optgroup,
button,
legend,
fieldset {
	box-sizing: border-box;
	outline: none;

	font-family: $fh-font-family;
	font-size: $fh-font-size;
	color: $fh-font-color;
	vertical-align: top;

	display: $fh-display;
	@include center-layout;
}


datalist {
	font-family: $fh-font-family;
	font-size: $fh-font-size;
}

label {
	display: $fh-display;
	@include center-layout;
}



/* Input & Textarea ------------------ */

/* Fields with standard width */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[list],
input[type="file"],
select,
textarea {
	width: $fh-input-width;
	max-width: $fh-input-max-width;
	padding: 0 ($fh-input-height / 4) ;
	background-color: $fh-input-bg-color;

	-webkit-border-radius: $fh-border-radius;
	-moz-border-radius: $fh-border-radius;
	border-radius: $fh-border-radius;
	border: $fh-border-width $fh-border-style $fh-border-color;
}

/* Fields with standard height */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[list] {
	height: $fh-input-height;
	-webkit-appearance: none;
}

/* Other */

textarea {
	-webkit-appearance: none;
	overflow: auto;
}

input[type="range"] {
	height: $fh-input-height;
	width: $fh-input-width;
	max-width: $fh-input-max-width;
}

input[type="file"] {
	min-height: $fh-input-height;
}

input[type="search"] {
	height: $fh-input-height;
	-webkit-appearance: none;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
	display: inline-block;
	vertical-align: middle;
}
// For checkbox and radio to be centered, need to wrap the input and label in a span -
// .checkbox-container {
// 	display: block;
// 	text-align: center;
// }


/* Select ------------------ */

select {
	height: $fh-input-height;
	color: black;

	@if $fh-allow-vendor-styling == false {
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
	}

}

.select-wrapper {
	position: relative;
	z-index: 0;
	width: $fh-input-width;
	max-width: $fh-input-max-width;

	>select {
		height: $fh-input-height;
		color: black;

		@if $fh-allow-vendor-styling == false {
			-webkit-appearance: none;
			-moz-appearance: none;
			-ms-appearance: none;
			-o-appearance: none;
		}

	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		z-index: 1;

		top: 0;
		bottom: 0;
		left: auto;
		right: 1em;

		margin: auto;

		@include triangle(14px, gray, down);
	}
}

select[multiple] {
	height: auto;
	min-height: $fh-input-height;
	padding: 0;

	option {
		margin: 0;
		padding: $fh-input-height / 5;
	}
}

/* Fieldset ------------------ */

fieldset {
	@if $fh-fieldset-bare == true {
		padding: 0;
		border: 0;
	}
	@else {
		padding: 10px 25px;
		-webkit-border-radius: $fh-border-radius;
		-moz-border-radius: $fh-border-radius;
		border-radius: $fh-border-radius;
		border: $fh-border-width $fh-border-style $fh-border-color;
	}
}

legend {
	@if $fh-fieldset-bare == true {
		padding: 0;
		font-weight: inherit;
	}
	@else {
		padding: 0 5px;
		font-weight: 700;
	}
}

/* Buttons, Input Type Submit/Reset ------------------ */

// button,
.button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="image"] {
	height: $fh-button-height;
	width: $fh-button-width;
	max-width: $fh-button-max-width;
	background-color: $fh-button-bg-color;
	padding: $fh-input-height / 5;
	cursor: pointer;

	color: $fh-button-font-color;
	font-weight: 700;

	-webkit-appearance: none;
	-moz-appearance: none;

	-webkit-border-radius: $fh-border-radius;
	-moz-border-radius: $fh-border-radius;
	border-radius: $fh-border-radius;
	border: $fh-border-width $fh-border-style $fh-border-color;

	@extend %no-select;
}

input[type="image"] {
	text-align: center;
	padding: $fh-input-height / 5;
}

/* States ------------------ */

input[disabled],
textarea[disabled],
select[disabled],
option[disabled],
button[disabled] {
	cursor: not-allowed;
}

input:focus,
textarea:focus,
select:focus,
option:focus,
// button:focus
{
	background-color: $fh-focus-bg-color;
	border-color: $fh-focus-border-color;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
	outline: $fh-focus-border-color solid 2px;
}

// button:hover,
// input[type="button"]:hover,
// input[type="submit"]:hover,
// input[type="reset"]:hover,
// button:focus,
// input[type="button"]:focus,
// input[type="submit"]:focus,
// input[type="reset"]:focus {
// 	background-color: $fh-button-hover-bg-color;
// 	color: $fh-button-hover-font-color;
// }
