#employee-tip-form {

  #hours-input {
    border: 1px solid #333;
    display: inline-block;
    width: 50%;
  }

  button {
    display: inline-block;
    width: 25%;
    margin-left: 10px;
    color: #fff;
  }
}

#employee-edit-form {
  input {
    border: 1px solid #333;
  }

  button {
    height: 60px;
    margin: 60px auto;
  }
}

#employee-add {
  .flex-container {
    background: none;
  }

  input {
    border: 1px solid #333;
  }

  button {
    height: 60px;
    margin: 60px auto;
  }
}
