#tip-view {

  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  background: $dark-green;

  display: flex;
  flex-flow: column;

  input {
    color: #000;
  }

  select {
    padding: 5px 10px;
  }

  button {
    background-color: $dark-green;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    display: block;
    margin: 0 auto;
  }
}

.tip-block {
  color: tint(black, 20);
  max-width: 600px;
  margin: 0 auto;

  // display: flex;
  // flex-flow: column;
  flex: 1 0 auto;
  overflow: scroll;
  max-height: calc(100% - 80px - 44px);

  // padding-top: (40px);

  @extend %-is-scrollable;
}

.bttn-next {
  flex: 0 0 auto;

  font-weight: bold;
  color: white;
  background: $blue;

  height: 80px;

  border: none;
}

// #tip-view >.back-nav {
  // height: 60px;
// }

.payment-input {
  height: 500px;
  padding: 10px;
}


.tip-input {
  & {
    font-size: 16px;
    // margin: 0 auto;
  }

  >input {
    font-size: 4em;
    // font-weight: bold;

    line-height: 100%;

    height: auto;
    margin: 0 auto;
    padding: 10px;

    text-align: center;

    border: none;
    border-radius: 0;

    color: white;
    background: none;

    height: 200px;
  }
}

.row-button {
  & {
    display: flex;
    flex-flow: row nowrap;

    margin: 16px;
  }


  >button {
    flex: 1;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.title-bar {
  & {
    position: relative;
    overflow: hidden;
    z-index: 0;

    height: 44px;
  }

  >.titletext {
    position: absolute;
    color: inherit;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    margin: auto;

    text-align: center;

    line-height: 40px;

    font-size: 16px;
    font-weight: bold;

    pointer-events: none;
  }

  >.back {
    background: none;
    outline: none;
    border: 0;
    
    display: inline-block;

    font-size: (16px);
    line-height: (40px);

    margin: 0 10px;

    color: $midlight-green;
    // color: lighten($green, 25);

    >.icon {
      position: relative;
      top: 2px;
      line-height: 40px;
      // font-size: (18px);
    }
  }
}
