#transaction-new {
  & {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    width: 100%;
    height: 100%;

    overflow: hidden;
    // overflow-y: scroll;
    overflow-y: auto;

    background: $dark-green;
    color: white;
  }

  // >.actions {
  //
  // }

  .bttn-next {
    // flex: 0 0 auto;

    width: 100%;

    font-weight: bold;
    color: white;
    background: $blue;

    height: 80px;

    border: none;
  }

  .segmented-control {
    border: 1px solid $primary-color;
    background-color: $dark-green;
    // color: $primary-color;
  }
  .control-item {
    & {
      background-color: $dark-green;
      color:  $primary-color;
      border-left:  $primary-color;
    }
    &.active {
      background-color:  $primary-color;
      color: $dark-green;
      font-weight: bold;
    }
  }

}

.flex-container {
  display: flex;
  flex-flow: column;

  height: 100%;

  >.body {
    flex: 1 0 auto;
    // height: 100%;
  }
  >.actions {
    flex: 0 0 auto;
  }
}
