.project-card {
  @extend %card-style;

  & {
    color: tint(black, 20);
  }
  >.body {
    padding: em(16);

  }
  >.head {
    // background: tint(black, 90);
    padding: 0 em(16);
    border-bottom: 1px solid tint(black, 100);
  }
  >.head >.name {
    position: relative;
    z-index: 1;

    font-size: em(22);
    height: em(40);
    line-height: em(50);
  }

  >.head >.address {
    position: relative;
    z-index: 1;

    font-size: em(12);
    height: em(60);
    line-height: em(60);

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
  .hours {
    ul {
      font-size: em(14);
      text-align: left;
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  .figure {
    background: tint(black, 80) center no-repeat;
    background-size: cover;
    height: 0;
    padding-top: 50%;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 0;
    //
    // &:after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: rgba(black, 0.5);
    // }
  }
}

.contact-bttn {
  // @extend %no-select;
  // background: $green;
  background: tint($dark-green, 10);
  color: white;

  display: block;

  width: calc(100% - (18*2px));
  // width: auto;
  max-width: none;

  font-size: em(12);

  height: (40px);
  line-height: (40px);

  border-radius: 20px;

  margin: em(16) auto;
  padding: 0;
}

.projectinfo {
  background: tint(black, 96);
  color: tint(black, 20);
  position: relative;
  // text-align: center;
  z-index: 0;
  // margin: em(16) auto;
  margin: em(16) em(8);

  border-radius: 4px;
  overflow: hidden;

  >.body {
    padding: em(16);

  }
  >.head {
    background: tint(black, 90);
    padding: 0 em(16);
    border-bottom: 1px solid tint(black, 100);
  }

  .name {
    position: relative;
    z-index: 1;

    font-size: em(22);

    height: em(40);
    line-height: em(50);
  }
  .address {
    position: relative;
    z-index: 1;
    font-size: em(12);

    height: em(60);
    line-height: em(60);

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
  .hours {
    ul {
      font-size: em(14);
      text-align: left;
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  .figure {
    background: tint(black, 80) center no-repeat;
    background-size: cover;
    height: 0;
    padding-top: 50%;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 0;
    //
    // &:after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: rgba(black, 0.5);
    // }
  }
}

.foursquare-bttn {
  $color: #F94877;

  @extend %no-select;

  & {
    position: relative;
    background: none;
    background: white;
    // color: white;
    color: $color;
    border: 2px solid $color;
  }

  &:hover,
  &:active {
    color: white;
    background: $color;
  }

  display: block;

  // width: auto;
  width: calc(100% - (18*2px));
  max-width: none;

  font-size: em(12);
  font-weight: bold;

  text-align: center;

  height: (40px);
  line-height: (40px - 4px);

  border-radius: 20px;

  margin: em(16) auto;
  padding: 0;

  span {
    position: relative;
    display: inline-block;
  }

  span::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 110%;
    height: 100%;
    width: 18px;
    background: none center no-repeat;
    background-image: url("/svg/icon-fsq-01.svg");
  }

}

.yelp-bttn {
  $color: #b3282d;

  @extend %no-select;

  & {
    position: relative;
    background: none;
    background: white;
    // color: white;
    color: $color;
    border: 2px solid $color;
  }

  &:hover,
  &:active {
    color: white;
    background: $color;
  }

  display: block;

  // width: auto;
  width: calc(100% - (18*2px));
  max-width: none;

  font-size: em(12);
  font-weight: bold;

  text-align: center;

  height: (40px);
  line-height: (40px - 4px);

  border-radius: 20px;

  margin: em(16) auto;
  padding: 0;

  span {
    position: relative;
    display: inline-block;
  }

  span::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 110%;
    height: 100%;
    width: 20px;
    background: none center no-repeat;
    background-image: url("/svg/icon-ylp-01.svg");
  }
}
