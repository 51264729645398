// #homescreen-view {}

.homescreen-block {
  display: block;
  position: absolute;
  z-index: 9999;
  top: 0;
  height: 100%;
  width: 100%;
  color: white;

  // background: shade($dark-green, 0);
  // background: tint(black, 10);
  background: linear-gradient(10deg, $dark-green 10%, shade($green, 20) 90%);
  // overflow: hidden;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >.icon {
    position: relative;
    background: 100% center no-repeat;
    background-image: url("/images/apple-touch-icon.png");
    background-size: cover;

    @include size(80px);

    overflow: hidden;
    border-radius: 4px;

    margin-top: -25%;
  }

  >.new_icon {
    position: relative;
    background: 100% center no-repeat;
    background-image: url("/images/new-apple-touch-icon.png");
    background-size: cover;

    @include size(80px);

    overflow: hidden;
    border-radius: 4px;

    margin-top: -25%;
  }

  >.text {
    position: relative;
    font-size: em(12);
    line-height: em(80);
    a {
      color: #fff;
    }
  }

  >.info {
    // align-self: flex-end;
    position: absolute;
    bottom: em(16*2);
    left: 0;
    right: 0;

    width: 100%;
    max-width: 300px;
    margin: 0 auto;

    background: white;
    color: tint(black, 20);
    padding: em(16) em(16 * 1.6);
    font-size: em(14);
    border-radius: $base-border-radius;

    &:after {
      content: '';
      position: absolute;
      bottom: -6px;
      left: 0;
      right: 0;
      margin: 0 auto;
      @include triangle(12px, white, down);
    }

    .share {
      width: em(18px);
      margin: 0 em(8px) -1px;
      display: inline;
    }
  }
}
