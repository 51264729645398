// absolute position block
// flex display block

#signup-view {
  position: absolute;
  overflow: hidden;

  height: 100%;
  width: 100%;
}

.flex-container {
  & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-flow: column;

    height: 100%;
    width: 100%;

    background: black center no-repeat;
    background-size: auto, cover;
    background-image:
      linear-gradient(to top, rgba(black, 0.92) 50%, rgba(black, 0.5)),
      url("/images/9.jpg")
    ;
  }

  >.body {
    flex: 1 1 auto;
    // background: rgba(black, 0.96);

    @extend %-is-scrollable;
  }

  // >.body >.info {
  //   text-align: center;
  //   color: white;
  //
  //   // padding: 10px 20px 40px;
  //
  //   a {
  //     color: inherit;;
  //   }
  // }

  // >.actions {
  //   flex: 0 0 auto;
  // }
  //
  // >.actions >button {
  //   display: block;
  //
  //   height: auto;
  //   width: 100%;
  //   max-width: none;
  //   margin: 0;
  //   padding: 0;
  //
  //   line-height: 80px;
  //
  //   background: lighten($green, 0);
  //   color: white;
  //
  //   border-radius: 0;
  // }
}

.logo {
  display: block;
  position: relative;
  margin: 40px auto;
  @include size(60px);

  background: url("/svg/ikc-logo-w.svg") center no-repeat;
  background-size: 60px 60px;
  border-radius: 10px;
}

.signup-form {
  & {
    // border-radius: 10px;
    // overflow: hidden;
    // padding: 20px;
    padding: 0 2rem;
  }

  select,
  input {
    background: rgba(white, 0.0);
    border-bottom: 2px solid rgba(white, 0.75);
    margin: 2rem auto;
    padding: 1rem 2px;
    height: auto;
    line-height: 1;
    font-size: rem(20);
    color: white;
    border-radius: 0;

    @include placeholder {
      color: tint(black, 50);
    }
  }
  .select-wrapper {
    margin: 0 auto;
  }

  // >div {
  //   input,select {
  //     // margin: 0;
  //     border-radius: 0;
  //     // background: rgba(white, 0.9);
  //   }
  // }
  // >div:not(:last-of-type) {
  //   border-bottom: 1px solid rgba(black, 0.0);
  // }

  .errors {
    margin: 20px auto;
  }
  .errors p {
    font-size: em(15px);
    margin-bottom: 0.5em;
    color: red;
  }

  button[type=submit] {
    @extend %rounded-button;
    margin: 2rem auto;
  }
  
  a {
    display: block;
    text-align: center;

    margin: 1rem auto;

    font-size: rem(16);
    line-height: 2rem;
  }
}
