input.currency-input {
  & {
    font-size: 4em;
    // font-weight: bold;

    text-align: center;
    line-height: 100%;

    margin: 0 auto;
    padding: 10px;

    // height: auto;
    height: 200px !important;

    border: none;
    border-radius: 0;

    color: white;
    background: none;
    background-color: initial !important;
  }

  &:active {
    background: none;
    background-color: initial !important;
  }
}
