// @charset "UTF-8";
// @if $border-box-sizing == true {
  html { // http://bit.ly/1qk2tVR
    box-sizing: border-box;
  }

  * {
    &,
    &:before,
    &:after {
      box-sizing: border-box;
    }
  }
// }

html,
body {
  position: relative;
  z-index: 0;

  height: 100%;
  width: 100%;

  // overflow: auto;
  overflow: hidden;
  // -webkit-overflow-scrolling: auto; /* stop scrolling immediately */
}

body {
  // height: calc(100% + 70px);
  display: flex;
  flex-direction: column;
  // height: 100%;
  // width: 100%;
  // overflow: hidden;
}

// helpers
%-is-scrollable {
  & {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }
}

%no-select {
  user-select: none;
  // -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

// #layout {}
.layout {
  & {
    position: absolute;
    // position: relative;
    z-index: 0;
    // height: inherit;
    // width: inherit;
    height: 100%;
    width: 100%;
    // height: calc(100% - 40px);

    display: flex;
    flex-direction: column;

    // background: tint($green, 5);
    background: tint($dark-green, 90);
  }

  >.header {
    // position: relative;
    max-height: 60px;
    z-index: 2;

    // position: absolute;
    // width: 100%;
  }

  // >.body,
  >.main {
    flex: 1 1 auto;
    height: 100%;

    position: relative;
    z-index: 0;

    @extend %-is-scrollable;

    // padding-bottom: 90px;
    // padding-top: 60px;

  }

  >.footer {
    // position: relative;
    max-height: 80px;
    z-index: 20;

    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

#app {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  // -webkit-overflow-scrolling: auto; /* stop scrolling immediately */

  color: tint(black, 20);
}

// .content {
//   color: tint(black, 20);
// }

// ratchet modifiers
.content.-flex {
  & {
    display: flex;
    flex-flow: column;
  }

  >.btn {
    width: auto;
    margin: 10px;
  }
}
